import { Controller } from "@hotwired/stimulus"
import { useDebounce } from 'stimulus-use'
export default class extends Controller {
  static targets = ["form", "subButton"];
  static debounces = ['search']

  connect() {
    useDebounce(this)
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  search(e) {
    console.log(e);
    
    this.formTarget.requestSubmit()
  }
}
