import { Controller } from "@hotwired/stimulus"
import Quill from 'quill'

export default class extends Controller {
  focused = false

  static targets = ['editor', 'textarea']

  static values = {
    options: {
      type: Object,
      default: {}
    }
  }

  connect() {
    const element = this.hasEditorTarget ? this.editorTarget : this.element
    this.editor = new Quill(element, {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          ['link', 'clean']
        ]
      },
      ...this.optionsValue,
    })

    this.textChange()
    this.editor.on('text-change', this.textChange)
  }

  textareaTargetConnected(el) {
    el.addEventListener('focus', this.changeFocus)
  }

  textareaTargetDisconnected(el) {
    el.removeEventListener('focus', this.changeFocus)
  }

  textChange = () => {
    if (this.editor.root.innerHTML === '<p><br></p>') {
      this.textareaTarget.value = ''
    } else {
      this.textareaTarget.value = this.editor.root.innerHTML
    }
  }

  changeFocus = (e) => {
    this.textareaTarget.blur()
    this.editor.focus()
  }
}
