import { Notyf } from 'notyf'

$(document).on('turbo:load', function () {

  $('input[name="type"]').change(function () {
    if ($(this).val() == 'Fund') {
      $.get('/categories', function (categories) {
        var select = $('<select data-signup-target="category" class="form__input form__input--password" name="category_id" required=""></select>');
        categories.forEach(function (category) {
          var option = $('<option></option>')
            .val(category.id)
            .text(category.title);
          select.append(option);
        });
        $('.input-box').after(select).after('<label class="form__label" for="category">Категория</label>');
        $('label[for="name"]').text('Название'); // изменение текста лейбла
        $('label[for="last-name"], input[name="last_name"]').hide(); // скрытие лейбла и инпута
        $('label[for="last-name"], input[name="last_name"]').prop('disabled', true).hide().removeAttr('required');
      });
    } else {
      $('select[name="category_id"], label[for="category"]').remove();
      $('label[for="name"]').text('Имя'); // изменение текста лейбла
      $('label[for="last-name"], input[name="last_name"]').prop('disabled', false).show().attr('required', true);
    }
  });

  $('#sigunUpForm').on('submit', function (event) {
    let notyf = new Notyf({
      duration: 3000,
      position: { x: "right", y: "top" },
      ripple: true,
    });
    event.preventDefault();
    var formData = $(this).serialize();
    var formUrl = $(this).attr("action");
    var formMethod = $(this).attr("method");

    $.ajax({
      method: "POST",
      url: formUrl,
      type: formMethod,
      data: formData,
      success: function (response) {
        notyf.success('Пожалуйста проверьте почту, вам была отправлена ссылка для подтверждения')
      },
      error: function (response) {
        notyf.dismissAll();
        response.responseJSON.error.forEach((element) => {
          notyf.error(element)
        })


      }
    });
  });



  $('#confirmForm').on('submit', function (event) {
    let notyf = new Notyf({
      duration: 3000,
      position: { x: "right", y: "top" },
      ripple: true,
    });
    event.preventDefault();
    var formData = $(this).serialize();
    var formUrl = $(this).attr("action");

    $.ajax({
      method: "POST",
      url: formUrl,
      data: formData,
      success: function (response) {
        window.location.href = response.link
      },
      error: function (response) {
        notyf.dismissAll();
        notyf.error(response.responseJSON.error)
      }
    });
  });

  $('#addPhoneForm').on('submit', function (event) {
    let notyf = new Notyf({
      duration: 3000,
      position: { x: "right", y: "top" },
      ripple: true,
    });
    event.preventDefault();
    var formData = $(this).serialize();
    var formUrl = $(this).attr("action");

    $.ajax({
      method: "POST",
      url: formUrl,
      data: formData,
      success: function (response) {
        $('#addPhoneForm').toggle();
        $('#confirmForm').toggle();

      },
      error: function (response) {
        notyf.dismissAll();
        response.responseJSON.error.forEach((element) => {
          notyf.error(element)
        })

      }
    });
  });

  $('#sendPassInst').on('submit', function (event) {
    let notyf = new Notyf({
      duration: 3000,
      position: { x: "right", y: "top" },
      ripple: true,
    });
    event.preventDefault();
    var formData = $(this).serialize();
    var formUrl = $(this).attr("action");

    $.ajax({
      method: "POST",
      url: formUrl,
      data: formData,
      success: function (response) {
        notyf.success('Вы получите электронное письмо с инструкциями по сбросу пароля в течение нескольких минут.')
      },
      error: function (response) {
        notyf.dismissAll();
        response.responseJSON.error.forEach((element) => {
          notyf.error(element)
        })

      }
    });
  });

});

