import { Controller } from "@hotwired/stimulus"
import { Notyf } from 'notyf'

export default class extends Controller {
 static targets = ["token", "expertId", "orderableId", "id", "type"];
 static overlay = document.getElementById("overlay");
 static successModal = document.getElementById("successModal");

 connect() {
 }

 notyf() {
  return new Notyf({
   duration: 3000,
   position: { x: "right", y: "top" },
   ripple: true,
  });
 }

 submit(e) {
  e.preventDefault();

  const notyf = this.notyf();

  let formAction = `/orders`;

  let data = {
   authenticity_token: this.tokenTarget.value,
   consumer_id: this.idTarget.value,
   orderable_id: this.orderableIdTarget.value,
   orderable_type: this.typeTarget.value,
  }


  axios
   .post(formAction, data)
   .then((res) => {
    console.log(res)
    successModal.style.display = "block";
    overlay.classList.add("overlay--show");

    //window.Turbo.visit(res.data.link);

   })
   .catch(function (error) {
    notyf.dismissAll();
    notyf.error(error.response.data.error);
   });
 }

}
