import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.burgerMenu();
  }

  /* Burger menu */
  burgerMenu() {
    const burger = document.querySelector('.menu__burger');
    const menuList = document.querySelector('.menu__list-wrapper');

    const overlay = document.querySelector('.overlay-main');
    //const body = document.querySelector('body');
    const menuLinks = document.querySelectorAll('.menu__list-link');

    function hideBurgerMenu() {
      menuList.classList.remove('active');

      burger.classList.remove('active-burger');
      overlay.classList.remove('overlay-main--show');
    }

    burger.addEventListener('click', () => {
      if (!menuList.classList.contains('active')) {
        menuList.classList.add('active');

        burger.classList.add('active-burger');

        overlay.classList.add('overlay-main--show');
      } else {
        hideBurgerMenu();
      }
    });
    /* Cкрываем мобильное меню по нажатию на ссылку в меню */
    menuLinks.forEach(link => {
      link.addEventListener('click', hideBurgerMenu);
    });

    // Брейкпойнт, на котором появляется бургер-меню
    window.addEventListener('resize', () => {
      if (window.innerWidth > 640) {
        menuList.classList.remove('active');

        burger.classList.remove('active-burger');
      }
    });

    //overlay.addEventListener('click', hideBurgerMenu);
  }

}
