import { Notyf } from 'notyf'

$(document).on('click', '#editFundNew, #closeEditFund, #editFundOverlay', function () {
 $('#editFundOverlay').toggle();
 $('#modalEditFund').toggle();
});


$('#modalEditFundForm').on('submit', function (event) {
 event.preventDefault();
 let notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
  ripple: true,
 });

 var formUrl = $(this).attr("action");

 const formData = new FormData();
 formData.append('authenticity_token', $('input[name="authenticity_token"]').val());
 formData.append('fund[first_name]', $('input[name="fund_first_name"]').val());
 formData.append('fund[category_id]', $('select[name="category_id"]').val());
 formData.append('fund[description]', $('textarea#editor5[name="description"]').val());
 formData.append('fund[city_id]', $('select[name="fund_city_id"]').val());


 const files = myDropzone.getAcceptedFiles();
 formData.append('fund[avatar]', files[0]);

 $.ajax({
  method: "PATCH",
  url: formUrl,
  data: formData,
  processData: false,
  contentType: false,
  success: function (response) {
    $(".client__profile-inner").load(" .client__profile-inner > *");
    $(".about").load(" .about > *");

     
   $('#editFundOverlay').toggle();
   $('#modalEditFund').toggle();

  },
  error: function (response) {
   console.log(response)
   notyf.dismissAll();
   response.responseJSON.error.forEach((element) => {
    notyf.error(element)
   })

  }
 });
});

