$(document).on('turbo:load', function () {

  $("#review, #rateBtn").click(function () {
    $("#rewOverlay").toggle(),
      $(".modal__review").toggle()
  });

  $("#rewOverlay").click(function () {
    $("#rewOverlay").toggle(),
      $(".modal__review").toggle()
  });
})