import { Notyf } from 'notyf'

let notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
  ripple: true,
 });

export function referalSubmit(event){
  event.preventDefault();
  const form = event.target;
  const data = $(form).serialize();

  $.ajax({
    method: "POST",
    url: "/add_referal",
    data: data,
    success: function (response) {
      $('#RefOverlay, #RefModal').toggle();
    },
    error: function (response) {
      notyf.dismissAll();
      notyf.error("Такого реферального кода не существует")
    }
  });

}

export function closeReferal(){
  $('#RefOverlay, #RefModal').toggle();
}