$(document).on('turbo:load', function () {

 function formatSearch(item) {
  var selectionText = item.text.split("|");
  var $returnString = $('<span>' + selectionText[0] + '</br><b>' + selectionText[1] + '</b></br>' + selectionText[2] + '</span>');
  return $returnString;
 };
 function formatSelected(item) {
  var selectionText = item.text.split("|");
  var $returnString = $('<span>' + selectionText[1].substring(0, 21) + '</span>');
  return $returnString;
 };
 $('.select2').select2({
  templateResult: formatSearch,
  templateSelection: formatSelected
 });

 function cat(item) {
  var selectionText = item.text;
  return selectionText;
 };


})