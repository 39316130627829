import { Notyf } from 'notyf'

$(document).on('click', '#editServiceNew, #closeEditService, #editServiceOverlay', function () {
  $('#editServiceOverlay').toggle();
  $('#modalEditService').toggle();
});


$('#modalEditServiceForm').on('submit', function (event) {
  event.preventDefault();
  let notyf = new Notyf({
    duration: 3000,
    position: { x: "right", y: "top" },
    ripple: true,
  });

  var formUrl = $(this).attr("action");

  const formData = new FormData();
  formData.append('authenticity_token', $('form#modalEditServiceForm input[name="authenticity_token"]').val());
  formData.append('title', $('input[name="title"]').val());
  formData.append('price', $('input[name="price"]').val());
  formData.append('description', $('textarea[name="description"]').val());

  let categoryIds = $('select[name="category_ids[]"]').val();
  $.each(categoryIds, function (index, categoryId) {
    formData.append('category_ids[]', categoryId);
  });

  let visibleCheckbox = $('input[name="visible"]');
  if (visibleCheckbox.is(':checked')) {
    formData.append('visible', 'false');
  } else {
    formData.append('visible', 'true');
  }

  let yardem = $('input[name="yardem"]');
  if (yardem.is(':checked')) {
    formData.append('yardem', 'true');
  } else {
    formData.append('yardem', 'false');
  }

  const files = myDropzone.getAcceptedFiles();
  formData.append('image', files[0]);

  $.ajax({
    method: "PATCH",
    url: formUrl,
    data: formData,
    processData: false,
    contentType: false,
    success: function (response) {
      $("#services").load(" #services > *");
      $('#editServiceOverlay').toggle();
      $('#modalEditService').toggle();

    },
    error: function (response) {
      console.log(response)
      notyf.dismissAll();
      response.responseJSON.forEach((element) => {
        notyf.error(element)
      })

    }
  });
});

