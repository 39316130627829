import { Notyf } from 'notyf'

$(document).on('click', '#openEditConsumer, #OpenEditUserModal, #modalEditUserOverlay, #closeUserModal', function () {
  $('#modalEditUserOverlay').toggle();
  $('#modalEditUser').toggle();
});

$('#editUserInputs').on('submit', function (event) {
  let notyf = new Notyf({
    duration: 3000,
    position: { x: "right", y: "top" },
    ripple: true,
  });

  event.preventDefault();

  var formData = $(this).serialize();
  var formUrl = $(this).attr("action");
  var formMethod = $(this).attr("method");

  $.ajax({
    method: "PATCH",
    url: formUrl,
    type: formMethod,
    data: formData,
    success: function (response) {
      $(".client__profile-inner").load(" .client__profile-inner > *");
      $('#modalEditUserOverlay').toggle();
      $('#modalEditUser').toggle();
    },
    error: function (response) {
      notyf.dismissAll();
      response.data.error.forEach((element) => {
        notyf.error(element)
      })
    }
  });
});


$(document).on('change', 'input[name="userAvatarFile"]', function () {
  var formData = new FormData();
  formData.append('authenticity_token', $('input[name="authenticity_token"]').val());
  formData.append('avatar', $(this)[0].files[0]);
  let notyf = new Notyf({
    duration: 3000,
    position: { x: "right", y: "top" },
    ripple: true,
  });


  $.ajax({
    url: $('input[name="action"]').val(),
    method: "PATCH",
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
    success: function (response) {
      $(".client__profile").load(" .client__profile > *");
      $(".menu__profile-link").load(" .menu__profile-link > *");

    },
    error: function (response) {
      if (Array.isArray(response.responseJSON)) {
        response.responseJSON.forEach(function (errorMsg) {
          notyf.error(errorMsg)
        });
      } else {
        let errorMessage = response.responseJSON.error[0];
        notyf.error(errorMessage)
      }
    }
  });
});

