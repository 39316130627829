import { Controller } from "@hotwired/stimulus";
import axios from 'axios';

export default class extends Controller {
  static targets = ["token", "expertId", "currentUserId"];

  connect() {
  }

  sendMessage(e) {
    e.preventDefault();
    let formAction = `/conversations`;

    let data = {
      authenticity_token: this.tokenTarget.value,
      receiver_id: this.expertIdTarget.value,
      sender_id: this.currentUserIdTarget.value
    }

    axios
      .post(formAction, data)
      .then((res) => {
        const location = res.data + "?orderValue=Здравствуйте, я по поводу заказа услуги"
        window.location.replace(location);

      })
      .catch(function (error) {
      });
  }

}

