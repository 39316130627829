$(document).on('turbo:load', function () {

 $("#events").toggle();

 $("#newsPrev, #eventPrev").click(function () {
  $("#events").toggle();
  $("#news").toggle();

  $("#newsPrev, #eventPrev").toggleClass("active")
 });

})


