import { Controller } from "@hotwired/stimulus";
import { Notyf } from 'notyf'
import axios from 'axios';

export default class extends Controller {
  static targets = [
    "token",
    "email",
    "password",
  ];

  notyf() {
    return new Notyf({
      duration: 3000,
      position: { x: "right", y: "top" },
      ripple: true,
    });
  }

  connect() {
  }

  submit(e) {
    e.preventDefault();

    const notyf = this.notyf();

    let formAction = `/login`;

    let data = {
      authenticity_token: this.tokenTarget.value,
      email: this.emailTarget.value,
      password: this.passwordTarget.value,
    }


    axios
      .post(formAction, data)
      .then((res) => {
        console.log(res)
        window.location.href = res.data.link;

      })
      .catch(function (error) {
        notyf.dismissAll();
        notyf.error(error.response.data.error);
      });
  }


  exit(e) {
    e.preventDefault();
    let formAction = `/exit`;

    let data = {
      authenticity_token: this.tokenTarget.value,
    }


    axios
      .post(formAction, data)
      .then((res) => {
        window.location.href = "/"

      })
  }
}
