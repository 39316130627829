import "./editUserInputs"
import "./signUp"
import "./addService"
import "./addProduct"
import "./editService"
import "./chat"
import "./editFund"
import "./orderUpdate"
import "./order"
import "./contact"


$(document).ready(function () {
  $('.overlay-main').click(function () {
    $(this).removeClass('overlay-main--show');
    $('.menu__list-wrapper').removeClass('active');
  });
});

$(document).ready(function () {
  $('#dropdownNotificationButton').click(function (event) {
    $('#dropdownNotification').toggle();
    event.stopPropagation();
    // Проверяем, что элемент отображается (display не равен "none")
    if ($('#dropdownNotification').css('display') !== 'none') {
      $.post('/notif_read', function (data) {
        // Обработка успешного выполнения запроса
        console.log('Запрос выполнен успешно');
      }).fail(function (err) {
        // Обработка ошибки запроса
        console.log(err);
      });
    }
    // Предотвращает всплытие события клика к родительским элементам
  });

  $(document).click(function (event) {
    var $target = $(event.target);
    if (!$target.closest('#dropdownNotification').length && !$target.is('#dropdownNotificationButton')) {
      $('#dropdownNotification').hide();
    }
  });
});


$(document).ready(function () {
  $('#helloOverlay, #buttonHelloClose, #closeHello').click(function (event) {
    event.stopPropagation();
    // Проверяем, что элемент отображается (display не равен "none")
    if ($('#helloModal').css('display') !== 'none') {
      $.post('/notif_read', function (data) {
        // Обработка успешного выполнения запроса
        console.log('Запрос выполнен успешно');
      }).fail(function (err) {
        // Обработка ошибки запроса
        console.log(err);
      });
    }

    
    $('#helloOverlay, #helloModal').toggle();
    $('#RefOverlay, #RefModal').toggle();

  });

});
