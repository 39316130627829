import { Controller } from "@hotwired/stimulus";
import { serialize } from "dom-form-serializer"
import { Notyf } from 'notyf'

export default class extends Controller {
 static targets = [
  "id",
  "token",
  "form",
  "preview",
  "avatar",
 ];

 notyf() {
  return new Notyf({
   duration: 3000,
   position: { x: "right", y: "top" },
   ripple: true,
  });
 }

 connect(evt) {
  let self = this;
  this.handleImagePreview();
  CKEDITOR.inline("fundDesc", {});
  const notyf = this.notyf();

  this.formTarget.addEventListener("submit", function (evt) {
   evt.preventDefault();

   let url = evt.target.action;
   let data = serialize(evt.target);

   const formData = new FormData();
   formData.append("authenticity_token", self.tokenTarget.value);
   formData.append('first_name', data.first_name);
   formData.append('description', data.description);
   formData.append('category_id', data.category_id);

   const avatar = self.avatarTarget.files[0];
   const file = avatar;
   if (file) {
    formData.append("avatar", file);
   }

   let formAction = evt.target.action

   axios
    .patch(formAction, formData)
    .then((res) => {
     notyf.dismissAll();
     notyf.success("Успешно");
     $(".main--service").load(" .main--service > *");
    })
    .catch(function (error) {
     notyf.dismissAll();
     error.response.data.error.forEach((element) => {
      notyf.error(element)
     })

    });
  });
 };


 handleImagePreview() {
  let prev = this.previewTarget;
  this.avatarTarget.addEventListener("change", function () {
   const file = this.files[0];
   if (file) {
    const reader = new FileReader();
    reader.addEventListener("load", function () {
     prev.style.backgroundImage = `url(${this.result})`;
    });
    reader.readAsDataURL(file);
   }
  });
 }

 formatSearch(item) {
  var selectionText = item.text.split("|");
  var $returnString = $('<span>' + selectionText[0] + '</br><b>' + selectionText[1] + '</b></br>' + selectionText[2] + '</span>');
  return $returnString;
 };

 formatSelected(item) {
  var selectionText = item.text.split("|");
  var $returnString = $('<span>' + selectionText[0].substring(0, 21) + '</span>');
  return $returnString;
 };

}
