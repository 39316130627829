import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static editServiceModal = document.getElementById("editServiceModal");

  static successModal = document.getElementById("successModal");


  connect() {
  }


  showModal() {
    editServiceModal.style.display = "block";
    document.getElementById("overlay").classList.add("overlay--show");
  }

  closeModal() {
    editServiceModal.style.display = "none";
    document.getElementById("overlay").classList.remove("overlay--show");
  }

  closeSuccessModal() {
    successModal.style.display = "none";
    document.getElementById("overlay").classList.remove("overlay--show");

  }
}
