import { Notyf } from 'notyf'

$(document).on('change', '.request__status', function (e) {
 let notyf = new Notyf({
  duration: 3000,
  position: { x: "right", y: "top" },
  ripple: true,
 });

 e.preventDefault();
 var form = $(this).closest('form');
 var formAction = form.attr('action');
 var authenticityToken = form.find('input[name="authenticity_token"]').val();
 var formData = {
  authenticity_token: authenticityToken,
  status: $(this).val()
 };

 $.ajax({
  url: formAction,
  type: 'PATCH',
  data: formData,
  success: function (response) {
   console.log('Form submitted successfully');
  },
  error: function (error) {
   notyf.dismissAll();
   error.responseJSON.error.forEach((element) => {
    notyf.error(element)
   })

  }
 });
});
