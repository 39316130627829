import { Turbo } from '@hotwired/turbo-rails'
import '../jquery/index'
import IMask from 'imask'

Turbo.start()

import { referalSubmit, closeReferal } from "../custom/referalSubmit.js"
import "../controllers/index"
import "../components/index"

import "../custom/swiper"
import "../custom/select"
import "../custom/openReview"
import "../custom/news"

window.closeReferal = closeReferal;
window.referalSubmit = referalSubmit;
window.IMask = IMask;