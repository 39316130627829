import { Controller } from "@hotwired/stimulus";
import axios from 'axios';
import { Fancybox } from "@fancyapps/ui";

import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
Swiper.use([Navigation])

export default class extends Controller {
  swiper = null
  gallery = null
  
  static targets = ['swiper', 'slide', 'textByEmpty']
  static values = {
    expertId: Number
  }

  connect() {
    if(!this.swiper) {
      this.initializeSwiper()
      this.updateSwiper()
    }
    if(!this.gallery) this.initializeFuncyboxGallery()
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = null
  }

  slideTargetConnected(e) {
    this.updateSwiper()
  }

  slideTargetDisconnected(e) {
    this.updateSwiper()
  }

  initializeSwiper() {
    this.swiper = new Swiper(this.swiperTarget, {
      slidesPerView: 1,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        860: {
          slidesPerView: 3,
          spaceBetween: 22,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      },
    });
  }

  initializeFuncyboxGallery() {
    this.gallery = Fancybox.bind('[data-fancybox="gallery"]', {});
  }

  updateSwiper() {
    if (!this.swiper) return
    this.swiper.update()

    if (this.swiper.slides.length === 0) {
      this.swiper.hostEl.style.display = 'none'
      this.textByEmptyTarget.style.display = 'block'
    }
    if (this.swiper && this.swiper.slides.length > 0) {
      this.swiper.hostEl.style.display = 'block'
      this.textByEmptyTarget.style.display = 'none'
    }
  }
}