import { Controller } from "@hotwired/stimulus";
import { serialize } from "dom-form-serializer"
import { Notyf } from 'notyf'
import axios from 'axios';

export default class extends Controller {
  static targets = [
    "token",
    "form",
  ];

  notyf() {
    return new Notyf({
      duration: 3000,
      position: { x: "right", y: "top" },
      ripple: true,
    });
  }

  connect(evt) {
    let self = this;
    const notyf = this.notyf();

    this.formTarget.addEventListener("submit", function (evt) {
      evt.preventDefault();

      let url = evt.target.action;
      let data = { authenticity_token: self.tokenTarget.value, reviews: serialize(evt.target) };

      axios
        .post(url, data)
        .then((res) => {
          notyf.dismissAll();
          notyf.success("Успешно");
          $(".opinions").load(" .opinions > *");
          $(".reviews").load(" .reviews > *");
          $("#newRewOverlay").toggle();
          $("#rewMod").toggle();
        })
        .catch(function (error) {
          notyf.dismissAll();
          error.response.data.error.forEach((element) => {
            notyf.error(element)
          })

        });
    });
  };


  handleImagePreview() {
    let prev = this.previewTarget;
    this.avatarTarget.addEventListener("change", function () {
      const file = this.files[0];
      if (file) {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
          prev.style.backgroundImage = `url(${this.result})`;
        });
        reader.readAsDataURL(file);
      }
    });
  }


}
