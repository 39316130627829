import { Notyf } from 'notyf'

$(document).on('click', '#openOrderModal, #OrderModal, #OrderOverlay', function () {
  $('#orderModal').toggle();
  $('#OrderOverlay').toggle();
});

$('#orders').on('click', '#editOrderOverlay', function () {
  $('#editOrderModal').toggle();
  $('#editOrderOverlay').toggle();
  const form = $('#orderModals');
  let originalAction = form.attr('action');
  let segments = originalAction.split('/');
  let lastSegment = segments.pop();
  let newAction = segments.join('/');
  form.attr('action', newAction);

});

$(document).on('click', '#editTime', function () {
  $('#editOrderModal').toggle();
  $('#editOrderOverlay').toggle();

  const orderId = $(this).data('order');
  const form = $('#orderModals');
  let originalAction = form.attr('action');
  form.attr('action', originalAction + '/' + orderId);

});


$(document).ready(function () {
  // При отправке формы:
  $(document).on('submit', '#orderModals', function (e) {
    e.preventDefault(); // Отменяем стандартное поведение браузера
    let notyf = new Notyf({
      duration: 3000,
      position: { x: "right", y: "top" },
      ripple: true,
    });

    // Отправляем данные формы методом POST на указанный URL
    $.ajax({
      type: 'POST',
      url: $(this).attr('action'),
      data: $(this).serialize(),
      success: function (response) {
        $('#orderModal').toggle();
        $('#OrderOverlay').toggle();

        let overlay = document.getElementById("overlay")
        if (overlay) {
          document.getElementById("overlay").classList.add("overlay--show");
        }

        $('#successModal').toggle();
        $('#editOrderModal').toggle();
        $('#editOrderOverlay').toggle();
        const form = $('#orderModals');

        let originalAction = form.attr('action');
        let segments = originalAction.split('/');
        let lastSegment = segments.pop();
        let newAction = segments.join('/');
        form.attr('action', newAction);
        $("#orders").load(" #orders > *");

      },
      error: function (error) {
        console.log('Ошибка при отправке формы:', error);
        notyf.dismissAll();
        notyf.error(error.responseJSON.error)
      }
    });
  });
});
