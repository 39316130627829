$('#myform').submit(function (event) {
 event.preventDefault();
 var formUrl = $(this).attr("action");

 $.ajax({
  url: formUrl,
  method: 'POST',
  data: $(this).serialize()
 })
  .done(function (response) {
   $('#message_body').val('');
  })
  .fail(function (jqXHR, textStatus, errorThrown) {
   // handle the error
  });
});




$(document).on('turbo:load', function () {
 const el = document.querySelector('#messages');
 if (!el) {
  return;
 }

 const messages = $('#messages')[0];

 const observer = new MutationObserver(() => {
  // Когда в блоке messages появляется новый div, скроллим в самый низ
  $('.chat-wrapper').scrollTop($('.chat-wrapper')[0].scrollHeight);

 });

 observer.observe(messages, { childList: true });

});

