import { Notyf } from 'notyf'

$(document).on('click', '#addServiceNew, #closeCreateService, #createServiceOverlay', function () {
  $('#createServiceOverlay').toggle();
  $('#modalCreateService').toggle();
});


$('#modalCreateServiceForm').on('submit', function (event) {
  event.preventDefault();
  let notyf = new Notyf({
    duration: 3000,
    position: {
      x: "right",
      y: "top"
    },
    ripple: true,
  });

  var formUrl = $(this).attr("action");
  const formData = new FormData();
  formData.append('authenticity_token', $('input[name="authenticity_token"]').val());
  formData.append('title', $('input[name="title"]').val());
  formData.append('price', $('input[name="price"]').val());
  
  formData.append('description', $('textarea[name="description"]').val());
  let visibleCheckbox = $('input[name="visible"]');
  if (visibleCheckbox.is(':checked')) {
    formData.append('visible', 'false');
  } else {
    formData.append('visible', 'true');
  }
  let yardem = $('input[name="yardem"]');
  if (yardem.is(':checked')) {
    formData.append('yardem', 'true');
  } else {
    formData.append('yardem', 'false');
  }

  const files = window.myDropzone.getAcceptedFiles();
  
  formData.append('image', files[0]);
  let categoryIds = $('select[name="category_ids[]"]').val();
  $.each(categoryIds, function (index, categoryId) {
    formData.append('category_ids[]', categoryId);
  });
  $.ajax({
    method: "POST",
    url: formUrl,
    data: formData,
    processData: false,
    contentType: false,
    success: function (response) {
      $("#services").load(" #services > *");
      $('#createServiceOverlay').toggle();
      $('#modalCreateService').toggle();
    },
    error: function (response) {
      console.log(response)
      notyf.dismissAll();
      response.responseJSON.error.forEach((element) => {
        notyf.error(element)
      })
    }
  });
});