import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules';
import 'swiper/css'

Swiper.use([Autoplay])

$(document).on('turbo:load', function () {
  new Swiper('.top-swip', {
    // Optional parameters
    slidesPerView: 1,

    autoplay: {
      delay: 4000,
    },
    /*   effect: 'fade',
    fadeEffect: {
      crossFade: true,
    }, */
    pagination: {
      el: '.swiper-pagination--top',
      clickable: true,
    },
  });

  new Swiper('.top__swiper-slider', {
    // Optional parameters
    slidesPerView: 1,

    autoplay: {
      delay: 4000,
    },
    /*   effect: 'fade',
    fadeEffect: {
      crossFade: true,
    }, */
    pagination: {
      el: '.swiper-pagination--top',
      clickable: true,
    },
  });

  new Swiper('.intro__swiper-slider', {
    // Optional parameters
    slidesPerView: 1,

    autoplay: {
      delay: 4000,
    },
    /*   effect: 'fade',
    fadeEffect: {
      crossFade: true,
    }, */
    pagination: {
      el: '.swiper-pagination--intro',
      clickable: true,
    },
  });

})