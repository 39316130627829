import { Notyf } from 'notyf'

$(document).on('turbo:load', function () {
  $('#contactForm').on('submit', function (event) {
    let notyf = new Notyf({
      duration: 3000,
      position: { x: "right", y: "top" },
      ripple: true,
    });
    event.preventDefault();
    var formData = $(this).serialize();
    var formUrl = $(this).attr("action");
    var formMethod = $(this).attr("method");

    $.ajax({
      method: "POST",
      url: formUrl,
      type: formMethod,
      data: formData,
      success: function (response) {
        notyf.dismissAll();
        notyf.success("Успешно")
      },
      error: function (response) {
        notyf.dismissAll();
        response.responseJSON.error.forEach((element) => {
          notyf.error(element)
        })


      }
    });
  });
});