import { Controller } from "@hotwired/stimulus"
import { Notyf } from 'notyf'

export default class extends Controller {
  duration = 3000
  static targets = [ 'flash' ]

  initialize() {
    this.notyf = new Notyf({
      duration: this.duration,
      position: { x: "right", y: "top" },
      ripple: true,
    })
  }

  flashTargetConnected(flash) {
    const message = flash.innerText
    const type = flash.dataset.type || 'success'
  
    if (message && type === 'error') {
      this.notyf.error(message)
    } else if (message) {
      this.notyf.success(message)
    }

    setTimeout(() => {
      flash.remove()
    }, this.duration)
  }
}
